var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4",
          attrs: {
            src: require("@/assets/images/pages/not-authorized.png"),
            alt: "graphic-not-authorized",
          },
        }),
        _c(
          "h1",
          {
            staticClass:
              "sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color",
          },
          [_vm._v("Conta de CFC bloqueada!")]
        ),
        _c(
          "div",
          { staticClass: "vx-col w-full mx-auto self-center" },
          [
            _c("vx-card", [
              _c(
                "div",
                {
                  staticClass: "full-page-bg-color",
                  attrs: { slot: "no-body" },
                  slot: "no-body",
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-full mx-auto self-center d-theme-dark-bg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "p-8" },
                          [
                            _c("div", { staticClass: "vx-card__title mb-8" }, [
                              _c("blockquote", [
                                _c("p", [_c("strong", [_vm._v("Atenção!")])]),
                                _c("p", [
                                  _vm._v("Sua conta encontra-se bloqueada."),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Por favor utilize o formulário abaixo para entrar em contato e saber mais detalhes."
                                  ),
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Se preferir pode enviar um email para contato@sigcfc.com.br."
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("vs-textarea", {
                              staticClass: "w-full",
                              attrs: { label: "Digite aqui a sua mensagem" },
                              model: {
                                value: _vm.message,
                                callback: function ($$v) {
                                  _vm.message = $$v
                                },
                                expression: "message",
                              },
                            }),
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "float-left w-full md:w-auto mt-4 mb-4",
                                attrs: { to: "/logout", type: "border" },
                              },
                              [_vm._v("Voltar ao login")]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass:
                                  "float-right w-full md:w-auto mt-4 mb-4 md:mt-4 md:mb-4",
                                attrs: { disabled: _vm.sendButton },
                                on: { click: _vm.sendEmail },
                              },
                              [_vm._v("Enviar mensagem")]
                            ),
                            _c(
                              "vs-alert",
                              {
                                staticClass: "mt-5",
                                attrs: {
                                  active: _vm.alert,
                                  "icon-pack": "feather",
                                  icon: "icon-info",
                                  closable: "",
                                  "close-icon": "icon-x",
                                },
                                on: {
                                  "update:active": function ($event) {
                                    _vm.alert = $event
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(_vm.messageAlert),
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "vs-alert",
                              {
                                staticClass: "mt-5",
                                attrs: {
                                  active: _vm.errorAlert,
                                  color: "danger",
                                  "icon-pack": "feather",
                                  icon: "icon-info",
                                  closable: "",
                                  "close-icon": "icon-x",
                                },
                                on: {
                                  "update:active": function ($event) {
                                    _vm.errorAlert = $event
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "Desculpe, não foi possível enviar a mensagem. Por favor envie sua mensagem diretamente ao email contato@sigcfc.com.br"
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }