<template>

  <div class="h-screen flex w-full bg-img">
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <img src="@/assets/images/pages/not-authorized.png" alt="graphic-not-authorized" class="mx-auto mb-4">
      <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">Conta de CFC bloqueada!</h1>
      <div class="vx-col w-full mx-auto self-center">
        <vx-card>
          <div slot="no-body" class="full-page-bg-color">
            <div class="vx-row">
              <div class="vx-col sm:w-full md:w-full lg:w-full mx-auto self-center d-theme-dark-bg">
                <div class="p-8">
                  <div class="vx-card__title mb-8">
                    <blockquote>
                      <p><strong>Atenção!</strong></p>
                      <p>Sua conta encontra-se bloqueada.</p>
                      <p>Por favor utilize o formulário abaixo para entrar em contato e saber mais detalhes.</p>
                      <p>Se preferir pode enviar um email para contato@sigcfc.com.br.</p>
                    </blockquote>
                  </div>
                  <vs-textarea class="w-full" label="Digite aqui a sua mensagem" v-model="message" />
                  <vs-button to="/logout" type="border" class="float-left w-full md:w-auto mt-4 mb-4">Voltar ao login</vs-button>
                  <vs-button :disabled="sendButton" @click="sendEmail" class="float-right w-full md:w-auto mt-4 mb-4 md:mt-4 md:mb-4">Enviar mensagem</vs-button>
                  <vs-alert :active.sync="alert" class="mt-5" icon-pack="feather" icon="icon-info" closable close-icon="icon-x">
                    <span v-html="messageAlert"></span>
                  </vs-alert>
                  <vs-alert :active.sync="errorAlert" class="mt-5" color="danger" icon-pack="feather" icon="icon-info" closable close-icon="icon-x">
                    <span>Desculpe, não foi possível enviar a mensagem. Por favor envie sua mensagem diretamente ao email contato@sigcfc.com.br</span>
                  </vs-alert>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios.js'

export default {
  data () {
    return {
      message: '',
      messageAlert: '',
      sendButton: false,
      alert: false,
      errorAlert: false
    }
  },
  methods: {
    async sendEmail () {

      this.sendButton = true
      this.$vs.loading()
      try {
        const resp = await axios.post(`${process.env.VUE_APP_API_URL}/send-feedback`, { message: this.message })
        this.messageAlert = resp.data.message
        this.alert = true
        this.errorAlert = false
        this.$vs.loading.close()
        setTimeout(() => { this.sendButton = false }, 5000)
        console.log(resp)
      } catch (error) {
        this.$vs.loading.close()
        this.alert = false
        this.errorAlert = true
        this.sendButton = false
      }
    }
  }
}
</script>
